<template>
  <NuxtLink :to="`/article/${data.slug}`">
    <article class="">
      <Image
      v-if="data?.image"
      :data="data.image"
      :xs="12"
      :sm="12"
      :md="10"
      :lg="12"
      :xl="12"
      classes="rounded"
      shape="square"
      />
      <div class="bg-gray-200 rounded-xl mt-1 p-4">
        <h1 class="text-black text-2xl font-semibold tracking-tight">
          {{ data.title }}
        </h1>
        <div 
        v-if="data?.articles_article_categories"
        class="mt-4"
        >
          <span 
          class="btn--cat text-xs pb-[2px] pt-[2px] px-[6px]"
          v-for="cat in data.articles_article_categories">
            {{ cat.article_categories.title }}
          </span>
        </div>
        <div class="flex justify-between mt-2">
          <div v-if="data?.articles_author" class="nba text-xs">
            {{ formattedAuthors(data?.articles_author) }}
          </div>
          <div v-if="data?.date" class="nba text-xs">
            {{ formattedDate(data?.date) }}
          </div>
        </div>
      </div>
    </article>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const formattedAuthors = authors => {
  if (!authors) return ''
  if (authors.length === 1) {
    return `By ${authors[0].author.name}`
  } else if (authors.length === 2) {
    return `By ${authors[0].author.name} & ${authors[1].author.name}`
  } else if (authors.length === 3) {
    return `By ${authors[0].author.name}, ${authors[1].author.name} & ${authors[2].author.name}`
  }
}

const formattedDate = givenDate => {
  const date = new Date(givenDate)
  return `${date.getDate()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
}
</script>